// Learn more on https://nuxtjs.org/guide/vuex-store

// =================================================
// State
// =================================================
export const state = () => ({
  load:{
    /*Load data format */
  },
  loadDetails: null,
  selectedImages: null,
  loadPaymentDetails: null
});

// =================================================
// Mutations
// =================================================
export const mutations = {
  setLoad: (store, load ) => {
    store.load =  load
  },
  setLoadDetails: (store, loadDetails ) => {
    store.loadDetails =  loadDetails
  },
  setImages: (store, images ) => {
    store.selectedImages =  images
  },
  setLoadPaymentStatus: (store, loadPaymentDetails ) => {
    store.loadPaymentDetails =  loadPaymentDetails
  }
}

// =================================================
// Actions
// =================================================
export const actions = {
  // eslint-disable-next-line no-unused-vars
  getLoadsListWithfilters({ state }, payload) {
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$post('/api/load-management/list', payload.filters, headers)
  },
  // Get list of categories
  getCategoriesList ({ state }, payload) {
    return this.$axios.$get('/api/load-management/category',{
      headers: {
        'Authorization': `${payload.tokenType} ${payload.token}`
      }
    })
  },
  // Get suburb relevent to text
  getSuburb ({ commit }, searchString) {
    return this.$axios.$get(`/api/lookup/suburb/${searchString}`)
  },
  // Save Car quote
  submitCarQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/car', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/car', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  }, 
  // Submit other quota
  submitOtherQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/other', payload.loadData, payload.user.token != null ? {
        headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/other', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit waste and recycling quota
  submitWasteAndRecyclingQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/waste-recycling', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/waste-recycling', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit ute and van cargo quota
  submitUteAndVanCargoQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/ute-van-cargo', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/ute-van-cargo', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit refridgerated quota
  submitRefridgeratedQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/refridgerated', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/refridgerated', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit Trucks / Trailers quota
  submitTrucksTrailersQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/truck-trailer', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/truck-trailer', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit agriculture quota
  submitAricultureQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/agriculture', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/agriculture', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit Heavy Haulage Oversize quota
  submitHeavyHaulageOversizeQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/heavy-haulage-oversize', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/heavy-haulage-oversize', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit motorcycle quota
  submitMotorcycleizeQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/motorcycle', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/motorcycle', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit conteniner or building quota
  submitContainerBuildingQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/container-building', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/container-building', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit general haulage quota
  submitGeneralHaulageQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/general-haulage', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/general-haulage', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit other-vehicle quota
  submitOtherVehicleQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/other-vehicle', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/other-vehicle', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit household or furniture quota
  submitHouseholdFurnitureQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/household-furniture', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/household-furniture', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit pets and livestock quota
  submitPetsQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/pets-livestock', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/pets-livestock', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit boats quota
  submitBoatsQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/boat', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/boat', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit rvs caravan  and trailer quota
  submitRvsCaravanTrailerQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/rvs-caravan-trailer', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/rvs-caravan-trailer', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  // Submit palletised-freight-package quota
  submitPalletisedFreightPackageQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/palletised-freight-package', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/palletised-freight-package', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
    // Submit Machinery Transport Quota
  submitMachineryTransportQuota({ state }, payload) {
    if (payload.type == 'put') {
      return this.$axios.$put('/api/load-management/machinery-transport', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    } else {
      return this.$axios.$post('/api/load-management/machinery-transport', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  },
  getLoadCategories () {
    return this.$axios.$get('/api/load-management/category')
  },

  // Fetch load details
  async getLoad({ commit }, payload){
    //TODO error handling

    const headers = payload.user.token ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    const load = await this.$axios.$get(`/api/load-management/summary?loadId=${payload.id}`, headers);
    commit('setLoad', {
      ...load?.result?.load,
      quoteId: load?.result?.quoteId,
      carrierPaymentPreference: load?.result?.carrierPaymentPreference,
      jobStatus: load?.result?.jobStatus,
      quoteAcceptStatus: load?.result?.quoteAcceptStatus,

    })
    commit('setLoadDetails', load?.result?.details)
    commit('setLoadPaymentStatus', load?.result?.paymentDetail)
    return load;
  },
  // withdraw load
  withdrawLoad({ state }, payload) {
    return this.$axios.$put(`/api/load-management/withdraw?loadId=${payload.loadId}`, null, payload.user.token != null ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null)
  },
  publishLoad({ state }, payload) { 
    return this.$axios.$put(`/api/load-management/publish?loadId=${payload.loadId}`, null, payload.user.token != null ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null)
  },
  // expire load relisting
  relistLoad({ state }, payload) {
    return this.$axios.$put('/api/load-management/relist', payload.data, payload.user.token != null ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null)
  },
  setLoadImages({ commit }, payload) {
    commit('setImages', payload)
    return true
  },
  //get total distance of active loads
  getTotalDistance () {
    return this.$axios.$get('/api/load-management/total-distance')
  },

    //get the visibility of the total distance of active loads
    getTotalDistanceVisibility () {
      return this.$axios.$get('/api/load-management/component-visibility')
    },
    //Complete load by carriar
    completeLoad({ state }, payload) {
      return this.$axios.$put('/api/load-management/complete', payload.loadData, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    },
}
