// Learn more on https://nuxtjs.org/guide/vuex-store

// =================================================
// State
// =================================================
export const state = () => ({
    load:{
      /*Load data format */
    },
    quotes: null,
  });

  
  // =================================================
  // Mutations
  // =================================================
  export const mutations = {
    SET_QUOTES(state, quotes) {
      state.quotes = quotes;
    },
  }
  
  // =================================================
  // Actions
  // =================================================
  export const actions = {
    subimtQuote({ state }, payload) {
      const headers = payload.user
        ? {
            headers: {
              Authorization: `${payload.user.tokenType} ${payload.user.token}`,
            },
          }
        : null;
      return this.$axios.$post('/api/quote', payload.data, headers);
    },
    // Get list of quote list for carrier
    async getQuoteList({ commit, state }, payload) {
      const res = await this.$axios.$get(
        `/api/quote/all?loadId=${payload.loadId}&page=${payload.page}&size=${payload.size}&isCarrier=${payload.isCarrier}`,
        {
          headers: {
            Authorization: `${payload.user.tokenType} ${payload.user.token}`,
          },
        }
      );
  
      commit('SET_QUOTES', res?.result);
      return res;
    },
    acceptQuote({ state }, payload) {
      const headers = payload.user
        ? {
            headers: {
              Authorization: `${payload.user.tokenType} ${payload.user.token}`,
            },
          }
        : null;
      return this.$axios.$put(
        `/api/quote/accept?loadId=${payload.loadId}&quoteId=${payload.quoteId}&paymentAction=${payload.paymentAction}`,
        null,
        headers
      );
    },
    directQuotePay({ state }, payload) {
      const headers = payload.user
        ? {
            headers: {
              Authorization: `${payload.user.tokenType} ${payload.user.token}`,
            },
          }
        : null;
      return this.$axios.$post(
        `/api/quote/pay-now?quoteId=${payload.quoteId}&loadId=${payload.loadId}`,
        null,
        headers
      );
    },
    getQuoteData({ state }, payload) {
      const headers = payload.user
        ? {
            headers: {
              Authorization: `${payload.user.tokenType} ${payload.user.token}`,
            },
          }
        : null;
      return this.$axios.$get(
        `/api/quote?loadId=${payload.loadId}&quoteId=${payload.quoteId}`,
        headers
      );
    },
    // Payout load which completed by carrier
    completeLoadPayout({ state }, payload) {
      return this.$axios.$put(
        '/api/quote/complete-payout',
        payload.data,
        payload.user.token != null
          ? {
              headers: {
                Authorization: `${payload.user.tokenType} ${payload.user.token}`,
              },
            }
          : null
      );
    },
    // remind shipper about payment
    remindLoadPayment({ state }, payload) {
      return this.$axios.$get(
        `/api/quote/remind-shipper?loadId=${payload.loadId}`,
        payload.user.token != null
          ? {
              headers: {
                Authorization: `${payload.user.tokenType} ${payload.user.token}`,
              },
            }
          : null
      );
    },
  };