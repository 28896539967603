export const FileUpload = () => import('../../components/FileUpload.vue' /* webpackChunkName: "components/file-upload" */).then(c => wrapFunctional(c.default || c))
export const FileUploadWithLink = () => import('../../components/FileUploadWithLink.vue' /* webpackChunkName: "components/file-upload-with-link" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FullPageLoader = () => import('../../components/FullPageLoader.vue' /* webpackChunkName: "components/full-page-loader" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const NewsAlertSlider = () => import('../../components/NewsAlertSlider.vue' /* webpackChunkName: "components/news-alert-slider" */).then(c => wrapFunctional(c.default || c))
export const Placeholder = () => import('../../components/Placeholder.vue' /* webpackChunkName: "components/placeholder" */).then(c => wrapFunctional(c.default || c))
export const ProfilePop = () => import('../../components/ProfilePop.vue' /* webpackChunkName: "components/profile-pop" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const CarouselCategory = () => import('../../components/carouselCategory.vue' /* webpackChunkName: "components/carousel-category" */).then(c => wrapFunctional(c.default || c))
export const CarouselLoads = () => import('../../components/carouselLoads.vue' /* webpackChunkName: "components/carousel-loads" */).then(c => wrapFunctional(c.default || c))
export const EmailVerification = () => import('../../components/emailVerification.vue' /* webpackChunkName: "components/email-verification" */).then(c => wrapFunctional(c.default || c))
export const GetAQuote = () => import('../../components/getAQuote.vue' /* webpackChunkName: "components/get-a-quote" */).then(c => wrapFunctional(c.default || c))
export const IdentityDocumentWarning = () => import('../../components/identityDocumentWarning.vue' /* webpackChunkName: "components/identity-document-warning" */).then(c => wrapFunctional(c.default || c))
export const StripeConnectAccountWarning = () => import('../../components/stripeConnectAccountWarning.vue' /* webpackChunkName: "components/stripe-connect-account-warning" */).then(c => wrapFunctional(c.default || c))
export const SubscribeWarning = () => import('../../components/subscribeWarning.vue' /* webpackChunkName: "components/subscribe-warning" */).then(c => wrapFunctional(c.default || c))
export const SubscribeWarningForPublicAndShippers = () => import('../../components/subscribeWarningForPublicAndShippers.vue' /* webpackChunkName: "components/subscribe-warning-for-public-and-shippers" */).then(c => wrapFunctional(c.default || c))
export const FaqsSearchField = () => import('../../components/faqs/searchField.vue' /* webpackChunkName: "components/faqs-search-field" */).then(c => wrapFunctional(c.default || c))
export const FindMyTruckFilter = () => import('../../components/find-my-truck/TruckFilter.vue' /* webpackChunkName: "components/find-my-truck-filter" */).then(c => wrapFunctional(c.default || c))
export const FindMyTruckListItem = () => import('../../components/find-my-truck/TruckListItem.vue' /* webpackChunkName: "components/find-my-truck-list-item" */).then(c => wrapFunctional(c.default || c))
export const LoadsItemDetails = () => import('../../components/loads/ItemDetails.vue' /* webpackChunkName: "components/loads-item-details" */).then(c => wrapFunctional(c.default || c))
export const LoadsLoadDetails = () => import('../../components/loads/LoadDetails.vue' /* webpackChunkName: "components/loads-load-details" */).then(c => wrapFunctional(c.default || c))
export const LoadsLoadFilters = () => import('../../components/loads/LoadFilters.vue' /* webpackChunkName: "components/loads-load-filters" */).then(c => wrapFunctional(c.default || c))
export const LoadsLoadListItem = () => import('../../components/loads/loadListItem.vue' /* webpackChunkName: "components/loads-load-list-item" */).then(c => wrapFunctional(c.default || c))
export const MessagesInbox = () => import('../../components/messages/Inbox.vue' /* webpackChunkName: "components/messages-inbox" */).then(c => wrapFunctional(c.default || c))
export const ModalCancelSubscriptionModal = () => import('../../components/modal/CancelSubscriptionModal.vue' /* webpackChunkName: "components/modal-cancel-subscription-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalCompleteLoadFeedbackModal = () => import('../../components/modal/CompleteLoadFeedbackModal.vue' /* webpackChunkName: "components/modal-complete-load-feedback-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalCompleteLoadModal = () => import('../../components/modal/CompleteLoadModal.vue' /* webpackChunkName: "components/modal-complete-load-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalCreateTruckModal = () => import('../../components/modal/CreateTruckModal.vue' /* webpackChunkName: "components/modal-create-truck-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalFeedbackModal = () => import('../../components/modal/FeedbackModal.vue' /* webpackChunkName: "components/modal-feedback-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalGetQuoteModal = () => import('../../components/modal/GetQuoteModal.vue' /* webpackChunkName: "components/modal-get-quote-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalLoadCardPaymentModal = () => import('../../components/modal/LoadCardPaymentModal.vue' /* webpackChunkName: "components/modal-load-card-payment-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalQuoteDetailsModal = () => import('../../components/modal/QuoteDetailsModal.vue' /* webpackChunkName: "components/modal-quote-details-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalReviewModal = () => import('../../components/modal/ReviewModal.vue' /* webpackChunkName: "components/modal-review-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalSubmitQuoteModal = () => import('../../components/modal/SubmitQuoteModal.vue' /* webpackChunkName: "components/modal-submit-quote-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalSubscriptionPlanModal = () => import('../../components/modal/SubscriptionPlanModal.vue' /* webpackChunkName: "components/modal-subscription-plan-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalCheckoutModal = () => import('../../components/modal/checkoutModal.vue' /* webpackChunkName: "components/modal-checkout-modal" */).then(c => wrapFunctional(c.default || c))
export const NavbarsLoginUserNavbar = () => import('../../components/navbars/LoginUserNavbar.vue' /* webpackChunkName: "components/navbars-login-user-navbar" */).then(c => wrapFunctional(c.default || c))
export const NavbarsPublicNavbar = () => import('../../components/navbars/PublicNavbar.vue' /* webpackChunkName: "components/navbars-public-navbar" */).then(c => wrapFunctional(c.default || c))
export const NotificationsNotificationItem = () => import('../../components/notifications/NotificationItem.vue' /* webpackChunkName: "components/notifications-notification-item" */).then(c => wrapFunctional(c.default || c))
export const NotificationsNotificationList = () => import('../../components/notifications/NotificationList.vue' /* webpackChunkName: "components/notifications-notification-list" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaymentMethodCard = () => import('../../components/payments/PaymentMethodCard.vue' /* webpackChunkName: "components/payments-payment-method-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileBusinessdetails = () => import('../../components/profile/Businessdetails.vue' /* webpackChunkName: "components/profile-businessdetails" */).then(c => wrapFunctional(c.default || c))
export const ProfileContactdetails = () => import('../../components/profile/Contactdetails.vue' /* webpackChunkName: "components/profile-contactdetails" */).then(c => wrapFunctional(c.default || c))
export const ProfileFreighttransport = () => import('../../components/profile/Freighttransport.vue' /* webpackChunkName: "components/profile-freighttransport" */).then(c => wrapFunctional(c.default || c))
export const ProfilePayments = () => import('../../components/profile/Payments.vue' /* webpackChunkName: "components/profile-payments" */).then(c => wrapFunctional(c.default || c))
export const ProfileDetails = () => import('../../components/profile/ProfileDetails.vue' /* webpackChunkName: "components/profile-details" */).then(c => wrapFunctional(c.default || c))
export const QuotesAddanotheritem = () => import('../../components/quotes/Addanotheritem.vue' /* webpackChunkName: "components/quotes-addanotheritem" */).then(c => wrapFunctional(c.default || c))
export const QuotesCollectionanddelivery = () => import('../../components/quotes/Collectionanddelivery.vue' /* webpackChunkName: "components/quotes-collectionanddelivery" */).then(c => wrapFunctional(c.default || c))
export const QuotesPersonaldetails = () => import('../../components/quotes/Personaldetails.vue' /* webpackChunkName: "components/quotes-personaldetails" */).then(c => wrapFunctional(c.default || c))
export const Quotes = () => import('../../components/quotes/Quotes.vue' /* webpackChunkName: "components/quotes" */).then(c => wrapFunctional(c.default || c))
export const ReviewsReviewListItem = () => import('../../components/reviews/ReviewListItem.vue' /* webpackChunkName: "components/reviews-review-list-item" */).then(c => wrapFunctional(c.default || c))
export const SidebarsSettingsSideBar = () => import('../../components/sidebars/SettingsSideBar.vue' /* webpackChunkName: "components/sidebars-settings-side-bar" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionDetailsPaymentDetails = () => import('../../components/subscription-details/payment-details.vue' /* webpackChunkName: "components/subscription-details-payment-details" */).then(c => wrapFunctional(c.default || c))
export const Subscription = () => import('../../components/subscription-details/subscription.vue' /* webpackChunkName: "components/subscription" */).then(c => wrapFunctional(c.default || c))
export const SvgsBestcarrier = () => import('../../components/svgs/Bestcarrier.vue' /* webpackChunkName: "components/svgs-bestcarrier" */).then(c => wrapFunctional(c.default || c))
export const SvgsBtnremove = () => import('../../components/svgs/Btnremove.vue' /* webpackChunkName: "components/svgs-btnremove" */).then(c => wrapFunctional(c.default || c))
export const SvgsCargotruck = () => import('../../components/svgs/Cargotruck.vue' /* webpackChunkName: "components/svgs-cargotruck" */).then(c => wrapFunctional(c.default || c))
export const SvgsDollar = () => import('../../components/svgs/Dollar.vue' /* webpackChunkName: "components/svgs-dollar" */).then(c => wrapFunctional(c.default || c))
export const SvgsFacebook = () => import('../../components/svgs/Facebook.vue' /* webpackChunkName: "components/svgs-facebook" */).then(c => wrapFunctional(c.default || c))
export const SvgsIconperson = () => import('../../components/svgs/Iconperson.vue' /* webpackChunkName: "components/svgs-iconperson" */).then(c => wrapFunctional(c.default || c))
export const SvgsLinkedin = () => import('../../components/svgs/Linkedin.vue' /* webpackChunkName: "components/svgs-linkedin" */).then(c => wrapFunctional(c.default || c))
export const SvgsPerson = () => import('../../components/svgs/Person.vue' /* webpackChunkName: "components/svgs-person" */).then(c => wrapFunctional(c.default || c))
export const SvgsPlaybtn = () => import('../../components/svgs/Playbtn.vue' /* webpackChunkName: "components/svgs-playbtn" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuote = () => import('../../components/svgs/Quote.vue' /* webpackChunkName: "components/svgs-quote" */).then(c => wrapFunctional(c.default || c))
export const SvgsSvgad = () => import('../../components/svgs/Svgad.vue' /* webpackChunkName: "components/svgs-svgad" */).then(c => wrapFunctional(c.default || c))
export const SvgsSvgchat = () => import('../../components/svgs/Svgchat.vue' /* webpackChunkName: "components/svgs-svgchat" */).then(c => wrapFunctional(c.default || c))
export const SvgsSvgchoice = () => import('../../components/svgs/Svgchoice.vue' /* webpackChunkName: "components/svgs-svgchoice" */).then(c => wrapFunctional(c.default || c))
export const SvgsSvgcommunicate = () => import('../../components/svgs/Svgcommunicate.vue' /* webpackChunkName: "components/svgs-svgcommunicate" */).then(c => wrapFunctional(c.default || c))
export const SvgsSvghours = () => import('../../components/svgs/Svghours.vue' /* webpackChunkName: "components/svgs-svghours" */).then(c => wrapFunctional(c.default || c))
export const SvgsSvgkey = () => import('../../components/svgs/Svgkey.vue' /* webpackChunkName: "components/svgs-svgkey" */).then(c => wrapFunctional(c.default || c))
export const SvgsSvglive = () => import('../../components/svgs/Svglive.vue' /* webpackChunkName: "components/svgs-svglive" */).then(c => wrapFunctional(c.default || c))
export const SvgsSvgnocommission = () => import('../../components/svgs/Svgnocommission.vue' /* webpackChunkName: "components/svgs-svgnocommission" */).then(c => wrapFunctional(c.default || c))
export const SvgsSvgnotification = () => import('../../components/svgs/Svgnotification.vue' /* webpackChunkName: "components/svgs-svgnotification" */).then(c => wrapFunctional(c.default || c))
export const SvgsSvgrepeat = () => import('../../components/svgs/Svgrepeat.vue' /* webpackChunkName: "components/svgs-svgrepeat" */).then(c => wrapFunctional(c.default || c))
export const SvgsSvgtransport = () => import('../../components/svgs/Svgtransport.vue' /* webpackChunkName: "components/svgs-svgtransport" */).then(c => wrapFunctional(c.default || c))
export const SvgsTruck = () => import('../../components/svgs/Truck.vue' /* webpackChunkName: "components/svgs-truck" */).then(c => wrapFunctional(c.default || c))
export const SvgsYoutube = () => import('../../components/svgs/Youtube.vue' /* webpackChunkName: "components/svgs-youtube" */).then(c => wrapFunctional(c.default || c))
export const SvgsBox = () => import('../../components/svgs/box.vue' /* webpackChunkName: "components/svgs-box" */).then(c => wrapFunctional(c.default || c))
export const LoadsFiltersCargoCategory = () => import('../../components/loads/filters/CargoCategory.vue' /* webpackChunkName: "components/loads-filters-cargo-category" */).then(c => wrapFunctional(c.default || c))
export const LoadsFiltersCustomerType = () => import('../../components/loads/filters/CustomerType.vue' /* webpackChunkName: "components/loads-filters-customer-type" */).then(c => wrapFunctional(c.default || c))
export const LoadsFiltersEquipmentTypeCategory = () => import('../../components/loads/filters/EquipmentTypeCategory.vue' /* webpackChunkName: "components/loads-filters-equipment-type-category" */).then(c => wrapFunctional(c.default || c))
export const LoadsFiltersLoadDate = () => import('../../components/loads/filters/LoadDate.vue' /* webpackChunkName: "components/loads-filters-load-date" */).then(c => wrapFunctional(c.default || c))
export const LoadsFiltersLoadTypes = () => import('../../components/loads/filters/LoadTypes.vue' /* webpackChunkName: "components/loads-filters-load-types" */).then(c => wrapFunctional(c.default || c))
export const LoadsFiltersPickupDelivery = () => import('../../components/loads/filters/PickupDelivery.vue' /* webpackChunkName: "components/loads-filters-pickup-delivery" */).then(c => wrapFunctional(c.default || c))
export const LoadsFiltersPickupDeliveryRegion = () => import('../../components/loads/filters/PickupDeliveryRegion.vue' /* webpackChunkName: "components/loads-filters-pickup-delivery-region" */).then(c => wrapFunctional(c.default || c))
export const LoadsFiltersRadiusCommon = () => import('../../components/loads/filters/RadiusCommon.vue' /* webpackChunkName: "components/loads-filters-radius-common" */).then(c => wrapFunctional(c.default || c))
export const LoadsFiltersSearch = () => import('../../components/loads/filters/Search.vue' /* webpackChunkName: "components/loads-filters-search" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesAgriculture = () => import('../../components/quotes/categories/Agriculture.vue' /* webpackChunkName: "components/quotes-categories-agriculture" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesBoats = () => import('../../components/quotes/categories/Boats.vue' /* webpackChunkName: "components/quotes-categories-boats" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesCars = () => import('../../components/quotes/categories/Cars.vue' /* webpackChunkName: "components/quotes-categories-cars" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesGeneralHaulage = () => import('../../components/quotes/categories/GeneralHaulage.vue' /* webpackChunkName: "components/quotes-categories-general-haulage" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesHeavyHaulage = () => import('../../components/quotes/categories/HeavyHaulage.vue' /* webpackChunkName: "components/quotes-categories-heavy-haulage" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesHouseholdAndFurniture = () => import('../../components/quotes/categories/HouseholdAndFurniture.vue' /* webpackChunkName: "components/quotes-categories-household-and-furniture" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesMachineryTransport = () => import('../../components/quotes/categories/MachineryTransport.vue' /* webpackChunkName: "components/quotes-categories-machinery-transport" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesMotorcycles = () => import('../../components/quotes/categories/Motorcycles.vue' /* webpackChunkName: "components/quotes-categories-motorcycles" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesOther = () => import('../../components/quotes/categories/Other.vue' /* webpackChunkName: "components/quotes-categories-other" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesOtherVehicles = () => import('../../components/quotes/categories/OtherVehicles.vue' /* webpackChunkName: "components/quotes-categories-other-vehicles" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesPalletisedfreightPackages = () => import('../../components/quotes/categories/PalletisedfreightPackages.vue' /* webpackChunkName: "components/quotes-categories-palletisedfreight-packages" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesPetsandLivestock = () => import('../../components/quotes/categories/PetsandLivestock.vue' /* webpackChunkName: "components/quotes-categories-petsand-livestock" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesPortableBuilding = () => import('../../components/quotes/categories/PortableBuilding.vue' /* webpackChunkName: "components/quotes-categories-portable-building" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesRVSCaravansTrailers = () => import('../../components/quotes/categories/RVSCaravansTrailers.vue' /* webpackChunkName: "components/quotes-categories-r-v-s-caravans-trailers" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesRefridgerated = () => import('../../components/quotes/categories/Refridgerated.vue' /* webpackChunkName: "components/quotes-categories-refridgerated" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesShippingContainers = () => import('../../components/quotes/categories/ShippingContainers.vue' /* webpackChunkName: "components/quotes-categories-shipping-containers" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesTrucksTrailers = () => import('../../components/quotes/categories/TrucksTrailers.vue' /* webpackChunkName: "components/quotes-categories-trucks-trailers" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesUteandVanCargo = () => import('../../components/quotes/categories/UteandVanCargo.vue' /* webpackChunkName: "components/quotes-categories-uteand-van-cargo" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesWasteandRecycling = () => import('../../components/quotes/categories/WasteandRecycling.vue' /* webpackChunkName: "components/quotes-categories-wasteand-recycling" */).then(c => wrapFunctional(c.default || c))
export const SvgsHowItWorksRating = () => import('../../components/svgs/how-it-works/Rating.vue' /* webpackChunkName: "components/svgs-how-it-works-rating" */).then(c => wrapFunctional(c.default || c))
export const SvgsHowItWorksShelves = () => import('../../components/svgs/how-it-works/Shelves.vue' /* webpackChunkName: "components/svgs-how-it-works-shelves" */).then(c => wrapFunctional(c.default || c))
export const SvgsHowItWorksTruck = () => import('../../components/svgs/how-it-works/Truck.vue' /* webpackChunkName: "components/svgs-how-it-works-truck" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesAgriculture = () => import('../../components/svgs/quotes/Agriculture.vue' /* webpackChunkName: "components/svgs-quotes-agriculture" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesBoats = () => import('../../components/svgs/quotes/Boats.vue' /* webpackChunkName: "components/svgs-quotes-boats" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesCars = () => import('../../components/svgs/quotes/Cars.vue' /* webpackChunkName: "components/svgs-quotes-cars" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesContainersportablebuildins = () => import('../../components/svgs/quotes/Containersportablebuildins.vue' /* webpackChunkName: "components/svgs-quotes-containersportablebuildins" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesGeneralhaulage = () => import('../../components/svgs/quotes/Generalhaulage.vue' /* webpackChunkName: "components/svgs-quotes-generalhaulage" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesHeavyoversizedhaulage = () => import('../../components/svgs/quotes/Heavyoversizedhaulage.vue' /* webpackChunkName: "components/svgs-quotes-heavyoversizedhaulage" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesHouseholdfurniture = () => import('../../components/svgs/quotes/Householdfurniture.vue' /* webpackChunkName: "components/svgs-quotes-householdfurniture" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesMachinerytransport = () => import('../../components/svgs/quotes/Machinerytransport.vue' /* webpackChunkName: "components/svgs-quotes-machinerytransport" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesMotorcycles = () => import('../../components/svgs/quotes/Motorcycles.vue' /* webpackChunkName: "components/svgs-quotes-motorcycles" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesOther = () => import('../../components/svgs/quotes/Other.vue' /* webpackChunkName: "components/svgs-quotes-other" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesOthervehicles = () => import('../../components/svgs/quotes/Othervehicles.vue' /* webpackChunkName: "components/svgs-quotes-othervehicles" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesPalletspackages = () => import('../../components/svgs/quotes/Palletspackages.vue' /* webpackChunkName: "components/svgs-quotes-palletspackages" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesPetslivestock = () => import('../../components/svgs/quotes/Petslivestock.vue' /* webpackChunkName: "components/svgs-quotes-petslivestock" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesRefrigerated = () => import('../../components/svgs/quotes/Refrigerated.vue' /* webpackChunkName: "components/svgs-quotes-refrigerated" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesRvcaravanstrailers = () => import('../../components/svgs/quotes/Rvcaravanstrailers.vue' /* webpackChunkName: "components/svgs-quotes-rvcaravanstrailers" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesTrucktrailers = () => import('../../components/svgs/quotes/Trucktrailers.vue' /* webpackChunkName: "components/svgs-quotes-trucktrailers" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesUteAndVanCargo = () => import('../../components/svgs/quotes/UteAndVanCargo.vue' /* webpackChunkName: "components/svgs-quotes-ute-and-van-cargo" */).then(c => wrapFunctional(c.default || c))
export const SvgsQuotesWasterecycling = () => import('../../components/svgs/quotes/Wasterecycling.vue' /* webpackChunkName: "components/svgs-quotes-wasterecycling" */).then(c => wrapFunctional(c.default || c))
export const QuotesCategoriesCommonImageUploader = () => import('../../components/quotes/categories/common/ImageUploader.vue' /* webpackChunkName: "components/quotes-categories-common-image-uploader" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
