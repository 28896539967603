import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=4dc868c5&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/var/app/application-ui-artifacts/utransport-application-ui/components/Logo.vue').default,Footer: require('/var/app/application-ui-artifacts/utransport-application-ui/components/Footer.vue').default})
