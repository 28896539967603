import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _989d3be8 = () => interopDefault(import('../pages/become-a-carrier/index.vue' /* webpackChunkName: "pages/become-a-carrier/index" */))
const _7a78ea71 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _1c13c8e3 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _5d402d30 = () => interopDefault(import('../pages/favourites/index.vue' /* webpackChunkName: "pages/favourites/index" */))
const _4af394f4 = () => interopDefault(import('../pages/find-transport-carrier/index.vue' /* webpackChunkName: "pages/find-transport-carrier/index" */))
const _3e0586b8 = () => interopDefault(import('../pages/get-transport-quotes/index.vue' /* webpackChunkName: "pages/get-transport-quotes/index" */))
const _3b4c99dc = () => interopDefault(import('../pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))
const _3509d4b6 = () => interopDefault(import('../pages/loads-board/index.vue' /* webpackChunkName: "pages/loads-board/index" */))
const _1bccd45c = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _edbaf270 = () => interopDefault(import('../pages/my-quotes/index.vue' /* webpackChunkName: "pages/my-quotes/index" */))
const _83ab4580 = () => interopDefault(import('../pages/my-reviews/index.vue' /* webpackChunkName: "pages/my-reviews/index" */))
const _3366399d = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _68a03643 = () => interopDefault(import('../pages/public-loads-shipper/index.vue' /* webpackChunkName: "pages/public-loads-shipper/index" */))
const _7729fa5a = () => interopDefault(import('../pages/public-trucks-carrier/index.vue' /* webpackChunkName: "pages/public-trucks-carrier/index" */))
const _e14e2434 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _21e9e8b2 = () => interopDefault(import('../pages/settings/change-password/index.vue' /* webpackChunkName: "pages/settings/change-password/index" */))
const _81f1ed3c = () => interopDefault(import('../pages/settings/edit-profile/index.vue' /* webpackChunkName: "pages/settings/edit-profile/index" */))
const _009aee78 = () => interopDefault(import('../pages/settings/my-profile/index.vue' /* webpackChunkName: "pages/settings/my-profile/index" */))
const _f201cb56 = () => interopDefault(import('../pages/settings/payment-details/index.vue' /* webpackChunkName: "pages/settings/payment-details/index" */))
const _5bb3f712 = () => interopDefault(import('../pages/settings/preferences/index.vue' /* webpackChunkName: "pages/settings/preferences/index" */))
const _210ed816 = () => interopDefault(import('../pages/settings/reviews/index.vue' /* webpackChunkName: "pages/settings/reviews/index" */))
const _0306ce4e = () => interopDefault(import('../pages/settings/subscription/index.vue' /* webpackChunkName: "pages/settings/subscription/index" */))
const _0d172bbe = () => interopDefault(import('../pages/settings/utransport-payment-details/index.vue' /* webpackChunkName: "pages/settings/utransport-payment-details/index" */))
const _617a0798 = () => interopDefault(import('../pages/subscription-signup/index.vue' /* webpackChunkName: "pages/subscription-signup/index" */))
const _4840eb7a = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _3a6f87cc = () => interopDefault(import('../pages/thank-you/index.vue' /* webpackChunkName: "pages/thank-you/index" */))
const _222f8d10 = () => interopDefault(import('../pages/business-details/identity-documents.vue' /* webpackChunkName: "pages/business-details/identity-documents" */))
const _778303d6 = () => interopDefault(import('../pages/business-details/information.vue' /* webpackChunkName: "pages/business-details/information" */))
const _3db462bf = () => interopDefault(import('../pages/faqs/articles/index.vue' /* webpackChunkName: "pages/faqs/articles/index" */))
const _5be0fe1e = () => interopDefault(import('../pages/faqs/categories/index.vue' /* webpackChunkName: "pages/faqs/categories/index" */))
const _48df2a2a = () => interopDefault(import('../pages/faqs/search/index.vue' /* webpackChunkName: "pages/faqs/search/index" */))
const _008b4696 = () => interopDefault(import('../pages/faqs/shipper/index.vue' /* webpackChunkName: "pages/faqs/shipper/index" */))
const _e9aa7958 = () => interopDefault(import('../pages/faqs/shipperfaq.vue' /* webpackChunkName: "pages/faqs/shipperfaq" */))
const _82770766 = () => interopDefault(import('../pages/find-transport-carrier/create-truck/index.vue' /* webpackChunkName: "pages/find-transport-carrier/create-truck/index" */))
const _104c934a = () => interopDefault(import('../pages/find-transport-carrier/edit-truck/index.vue' /* webpackChunkName: "pages/find-transport-carrier/edit-truck/index" */))
const _13e31f0d = () => interopDefault(import('../pages/gettransportquote/backloads.vue' /* webpackChunkName: "pages/gettransportquote/backloads" */))
const _255b6925 = () => interopDefault(import('../pages/gettransportquote/boat-transport.vue' /* webpackChunkName: "pages/gettransportquote/boat-transport" */))
const _685a1f37 = () => interopDefault(import('../pages/gettransportquote/car-transport.vue' /* webpackChunkName: "pages/gettransportquote/car-transport" */))
const _1848bd4e = () => interopDefault(import('../pages/gettransportquote/caravan-transport.vue' /* webpackChunkName: "pages/gettransportquote/caravan-transport" */))
const _e5ebf646 = () => interopDefault(import('../pages/gettransportquote/cheapest-excavator-quote.vue' /* webpackChunkName: "pages/gettransportquote/cheapest-excavator-quote" */))
const _2cdd99e4 = () => interopDefault(import('../pages/gettransportquote/container-transport.vue' /* webpackChunkName: "pages/gettransportquote/container-transport" */))
const _1091acc0 = () => interopDefault(import('../pages/gettransportquote/farm-machinery.vue' /* webpackChunkName: "pages/gettransportquote/farm-machinery" */))
const _4b012f65 = () => interopDefault(import('../pages/gettransportquote/general-freight.vue' /* webpackChunkName: "pages/gettransportquote/general-freight" */))
const _77e6ebd1 = () => interopDefault(import('../pages/gettransportquote/general-haulage.vue' /* webpackChunkName: "pages/gettransportquote/general-haulage" */))
const _78b16620 = () => interopDefault(import('../pages/gettransportquote/heavy-haulage.vue' /* webpackChunkName: "pages/gettransportquote/heavy-haulage" */))
const _62bd7751 = () => interopDefault(import('../pages/gettransportquote/Machinery-transport.vue' /* webpackChunkName: "pages/gettransportquote/Machinery-transport" */))
const _5aad8b16 = () => interopDefault(import('../pages/gettransportquote/Motorcycle-transport.vue' /* webpackChunkName: "pages/gettransportquote/Motorcycle-transport" */))
const _249cb62c = () => interopDefault(import('../pages/gettransportquote/moving-house.vue' /* webpackChunkName: "pages/gettransportquote/moving-house" */))
const _59bf6652 = () => interopDefault(import('../pages/gettransportquote/other.vue' /* webpackChunkName: "pages/gettransportquote/other" */))
const _7ae7f142 = () => interopDefault(import('../pages/gettransportquote/pallet-freight.vue' /* webpackChunkName: "pages/gettransportquote/pallet-freight" */))
const _d7e208d4 = () => interopDefault(import('../pages/gettransportquote/portable-buildings.vue' /* webpackChunkName: "pages/gettransportquote/portable-buildings" */))
const _156c0750 = () => interopDefault(import('../pages/gettransportquote/side-loader.vue' /* webpackChunkName: "pages/gettransportquote/side-loader" */))
const _0b94b296 = () => interopDefault(import('../pages/gettransportquote/Trailer-Transport.vue' /* webpackChunkName: "pages/gettransportquote/Trailer-Transport" */))
const _a35ca9ba = () => interopDefault(import('../pages/gettransportquote/truck-transportation.vue' /* webpackChunkName: "pages/gettransportquote/truck-transportation" */))
const _2a697705 = () => interopDefault(import('../pages/gettransportquote/water-tank-transport.vue' /* webpackChunkName: "pages/gettransportquote/water-tank-transport" */))
const _702a08e7 = () => interopDefault(import('../pages/faqs/articles/_id.vue' /* webpackChunkName: "pages/faqs/articles/_id" */))
const _ad981ff4 = () => interopDefault(import('../pages/faqs/categories/_id.vue' /* webpackChunkName: "pages/faqs/categories/_id" */))
const _7e67e17a = () => interopDefault(import('../pages/congratulation/_userId/index.vue' /* webpackChunkName: "pages/congratulation/_userId/index" */))
const _40e30cb2 = () => interopDefault(import('../pages/gettransportquote/_category/index.vue' /* webpackChunkName: "pages/gettransportquote/_category/index" */))
const _dd50763c = () => interopDefault(import('../pages/jobs/_jobId/index.vue' /* webpackChunkName: "pages/jobs/_jobId/index" */))
const _541ef1b2 = () => interopDefault(import('../pages/loads-board/_loadId/index.vue' /* webpackChunkName: "pages/loads-board/_loadId/index" */))
const _6ad5d5f5 = () => interopDefault(import('../pages/my-quotes/_quoteId/index.vue' /* webpackChunkName: "pages/my-quotes/_quoteId/index" */))
const _69aa919f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/become-a-carrier",
    component: _989d3be8,
    name: "become-a-carrier"
  }, {
    path: "/contact-us",
    component: _7a78ea71,
    name: "contact-us"
  }, {
    path: "/faqs",
    component: _1c13c8e3,
    name: "faqs"
  }, {
    path: "/favourites",
    component: _5d402d30,
    name: "favourites"
  }, {
    path: "/find-transport-carrier",
    component: _4af394f4,
    name: "find-transport-carrier"
  }, {
    path: "/get-transport-quotes",
    component: _3e0586b8,
    name: "get-transport-quotes"
  }, {
    path: "/jobs",
    component: _3b4c99dc,
    name: "jobs"
  }, {
    path: "/loads-board",
    component: _3509d4b6,
    name: "loads-board"
  }, {
    path: "/messages",
    component: _1bccd45c,
    name: "messages"
  }, {
    path: "/my-quotes",
    component: _edbaf270,
    name: "my-quotes"
  }, {
    path: "/my-reviews",
    component: _83ab4580,
    name: "my-reviews"
  }, {
    path: "/privacy-policy",
    component: _3366399d,
    name: "privacy-policy"
  }, {
    path: "/public-loads-shipper",
    component: _68a03643,
    name: "public-loads-shipper"
  }, {
    path: "/public-trucks-carrier",
    component: _7729fa5a,
    name: "public-trucks-carrier"
  }, {
    path: "/settings",
    component: _e14e2434,
    name: "settings",
    children: [{
      path: "change-password",
      component: _21e9e8b2,
      name: "settings-change-password"
    }, {
      path: "edit-profile",
      component: _81f1ed3c,
      name: "settings-edit-profile"
    }, {
      path: "my-profile",
      component: _009aee78,
      name: "settings-my-profile"
    }, {
      path: "payment-details",
      component: _f201cb56,
      name: "settings-payment-details"
    }, {
      path: "preferences",
      component: _5bb3f712,
      name: "settings-preferences"
    }, {
      path: "reviews",
      component: _210ed816,
      name: "settings-reviews"
    }, {
      path: "subscription",
      component: _0306ce4e,
      name: "settings-subscription"
    }, {
      path: "utransport-payment-details",
      component: _0d172bbe,
      name: "settings-utransport-payment-details"
    }]
  }, {
    path: "/subscription-signup",
    component: _617a0798,
    name: "subscription-signup"
  }, {
    path: "/terms-and-conditions",
    component: _4840eb7a,
    name: "terms-and-conditions"
  }, {
    path: "/thank-you",
    component: _3a6f87cc,
    name: "thank-you"
  }, {
    path: "/business-details/identity-documents",
    component: _222f8d10,
    name: "business-details-identity-documents"
  }, {
    path: "/business-details/information",
    component: _778303d6,
    name: "business-details-information"
  }, {
    path: "/faqs/articles",
    component: _3db462bf,
    name: "faqs-articles"
  }, {
    path: "/faqs/categories",
    component: _5be0fe1e,
    name: "faqs-categories"
  }, {
    path: "/faqs/search",
    component: _48df2a2a,
    name: "faqs-search"
  }, {
    path: "/faqs/shipper",
    component: _008b4696,
    name: "faqs-shipper"
  }, {
    path: "/faqs/shipperfaq",
    component: _e9aa7958,
    name: "faqs-shipperfaq"
  }, {
    path: "/find-transport-carrier/create-truck",
    component: _82770766,
    name: "find-transport-carrier-create-truck"
  }, {
    path: "/find-transport-carrier/edit-truck",
    component: _104c934a,
    name: "find-transport-carrier-edit-truck"
  }, {
    path: "/gettransportquote/backloads",
    component: _13e31f0d,
    name: "gettransportquote-backloads"
  }, {
    path: "/gettransportquote/boat-transport",
    component: _255b6925,
    name: "gettransportquote-boat-transport"
  }, {
    path: "/gettransportquote/car-transport",
    component: _685a1f37,
    name: "gettransportquote-car-transport"
  }, {
    path: "/gettransportquote/caravan-transport",
    component: _1848bd4e,
    name: "gettransportquote-caravan-transport"
  }, {
    path: "/gettransportquote/cheapest-excavator-quote",
    component: _e5ebf646,
    name: "gettransportquote-cheapest-excavator-quote"
  }, {
    path: "/gettransportquote/container-transport",
    component: _2cdd99e4,
    name: "gettransportquote-container-transport"
  }, {
    path: "/gettransportquote/farm-machinery",
    component: _1091acc0,
    name: "gettransportquote-farm-machinery"
  }, {
    path: "/gettransportquote/general-freight",
    component: _4b012f65,
    name: "gettransportquote-general-freight"
  }, {
    path: "/gettransportquote/general-haulage",
    component: _77e6ebd1,
    name: "gettransportquote-general-haulage"
  }, {
    path: "/gettransportquote/heavy-haulage",
    component: _78b16620,
    name: "gettransportquote-heavy-haulage"
  }, {
    path: "/gettransportquote/Machinery-transport",
    component: _62bd7751,
    name: "gettransportquote-Machinery-transport"
  }, {
    path: "/gettransportquote/Motorcycle-transport",
    component: _5aad8b16,
    name: "gettransportquote-Motorcycle-transport"
  }, {
    path: "/gettransportquote/moving-house",
    component: _249cb62c,
    name: "gettransportquote-moving-house"
  }, {
    path: "/gettransportquote/other",
    component: _59bf6652,
    name: "gettransportquote-other"
  }, {
    path: "/gettransportquote/pallet-freight",
    component: _7ae7f142,
    name: "gettransportquote-pallet-freight"
  }, {
    path: "/gettransportquote/portable-buildings",
    component: _d7e208d4,
    name: "gettransportquote-portable-buildings"
  }, {
    path: "/gettransportquote/side-loader",
    component: _156c0750,
    name: "gettransportquote-side-loader"
  }, {
    path: "/gettransportquote/Trailer-Transport",
    component: _0b94b296,
    name: "gettransportquote-Trailer-Transport"
  }, {
    path: "/gettransportquote/truck-transportation",
    component: _a35ca9ba,
    name: "gettransportquote-truck-transportation"
  }, {
    path: "/gettransportquote/water-tank-transport",
    component: _2a697705,
    name: "gettransportquote-water-tank-transport"
  }, {
    path: "/faqs/articles/:id",
    component: _702a08e7,
    name: "faqs-articles-id"
  }, {
    path: "/faqs/categories/:id",
    component: _ad981ff4,
    name: "faqs-categories-id"
  }, {
    path: "/congratulation/:userId",
    component: _7e67e17a,
    name: "congratulation-userId"
  }, {
    path: "/gettransportquote/:category",
    component: _40e30cb2,
    name: "gettransportquote-category"
  }, {
    path: "/jobs/:jobId",
    component: _dd50763c,
    name: "jobs-jobId"
  }, {
    path: "/loads-board/:loadId",
    component: _541ef1b2,
    name: "loads-board-loadId"
  }, {
    path: "/my-quotes/:quoteId",
    component: _6ad5d5f5,
    name: "my-quotes-quoteId"
  }, {
    path: "/",
    component: _69aa919f,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
