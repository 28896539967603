export const state = () => ({
  faqCategories: null,
})

export const mutations = {
  SET_FAQ_CATEGORIES (store, data) {
    store.faqCategories = data
  },
}

// =================================================
// Actions
// =================================================
export const actions = {
    // Get list of faq categories
    async getListOfCategories({ commit }) {
      const response =  await this.$axios.$get('/api/faqs/category')
      commit('SET_FAQ_CATEGORIES', response.result);
      return response;
    },
    // Get questions for specific categories
    getQuestions({ state }, categoryId) {
      return this.$axios.$get(`/api/faqs/${categoryId}?questionStatus=1`)
    },
    // Get relevent answer for selected question
    getAnswer({ state }, questionId) {
      return this.$axios.$get(`/api/faqs?id=${questionId}`)
    },
    searchQuestion({ state }, payload) {
      return this.$axios.$get(`/api/faqs/search/${payload.searchString}?page=${payload.page}&size=${payload.size}`)
    }
  }
  