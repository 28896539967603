
import { UserManager } from 'oidc-client';
import configurations from '~/plugins/oidc-client-config.js';
import GetQuoteModal from '~/components/modal/GetQuoteModal';
import { configs } from '../../config/config.keys'

export default {
  components: {
    GetQuoteModal
  },
  data() {
    return {
      userMgr: null,
      isModalVisible: false
    };
  },
  created() {
    if (!process.server) {
      this.userMgr = new UserManager(configurations.authConfigHomeLogin);
    }
  },
  methods: {
    login() {
      if(this.$route.path.includes('/loads-board/')){
        configurations.authConfigHomeLogin.redirect_uri = window.location.origin + this.$route.path;
        this.userMgr = new UserManager(configurations.authConfigHomeLogin);
      }
      return this.userMgr.signinRedirect({ state: 'dskjqmoiqQdjnJHOnGn' });
    },
    signUp() {
      window.location.href =
        configs.shipperSignUpUrl;
    },
    goToWorksPage() {
      this.$router.push({
        path: '/get-transport-quotes',
      });
    },
    showQuoteModal() {
      this.isModalVisible = true
    },
    closeQuoteModal() {
      this.isModalVisible = false;
    },
    redirectToContactus(){
      this.$router.push({
        path: '/contact-us'
      });
    },
    redirectToBecomeACarrier(){
      this.$router.push({
        path: '/become-a-carrier'
      });
    }
  },
};
